import { css } from "@emotion/react";

import { UpgradePlanButton } from "components/recruit/shared/UpgradePlanButton";

import { useRecruitPlanContext } from "contexts/PlanGatingContext";

import { useInterviewOverviewRealtime } from "apis/recruit";
import { useRecruiterTeam } from "apis/recruitingTeam";

import { getFormatDistanceDate } from "utils/time";

import { purple, white } from "constants/colors";
import { nextUpgradedPlanName } from "constants/recruit";

import { Disclaimer } from "../library/Disclaimer";
import { Typography } from "../library/Typography";

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  upgrade: css({
    display: "flex",
    padding: 16,
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
    borderRadius: 16,
    background: purple[800],
  }),
};

export const UpgradeSection = () => {
  const { team } = useRecruiterTeam();
  const { total: totalFlows } = useInterviewOverviewRealtime();
  const { plan } = useRecruitPlanContext();

  return (
    <div css={style.wrapper} data-testid="interview-calls-counter">
      {(totalFlows || 0) >= plan.interviewFlowsCap && (
        <Disclaimer
          type={"error"}
          title={"Limit reached: Upgrade to create more interview flows."}
        >
          {!!plan.hasPaymentMethod && team?.isOwner && (
            <UpgradePlanButton
              color="red"
              size="small"
              currentPlan={plan}
              defaultPlanName={nextUpgradedPlanName[plan.planName]}
            >
              Upgrade
            </UpgradePlanButton>
          )}
        </Disclaimer>
      )}

      {/* upgrade widget */}
      {!plan.hasPaymentMethod && team?.isOwner && plan.trialEnd && (
        <div css={style.upgrade}>
          <Typography color={white} weight="bold">
            Trial ends {getFormatDistanceDate(new Date(plan.trialEnd))}
          </Typography>
          <UpgradePlanButton
            color="gray"
            background="filled"
            size="small"
            currentPlan={plan}
          >
            Upgrade
          </UpgradePlanButton>
        </div>
      )}
    </div>
  );
};
