import { PropsWithChildren } from "react";

import { css } from "@emotion/react";

import { ErrorBoundary } from "@sentry/nextjs";
import { useRouter } from "next/router";

import { ChatHistory } from "components/chat/ChatHistory";
import { DocumentsRightPanel } from "components/documents/DocumentsRightPanel";
import { JobsCopilotRightPanel } from "components/jobs/JobsCopilotRightPanel";
import { ResumeRightPanel } from "components/resume/ResumeRightPanel";
import { SearchBar } from "components/search/SearchBar";
import { Recommendations } from "components/shared/layout/Recommendations";

import { PageLayoutProvider } from "contexts/PageLayoutContext";

import { down } from "utils/mediaQueryStrings";
import {
  getCommunityUrl,
  getDocumentsUrl,
  getEarningsUrl,
  getGenieOverviewUrl,
  getJobsCopilotUrl,
  getResumeUrl,
} from "utils/urls";

import { uiGray, white } from "constants/colors";

import { Authenticated } from "../library/Authenticated";
import { EarningsRightPanel } from "./EarningsRightPanel";
import { InterviewRightPanel } from "./InterviewRightPanel";
import { LeftPanel } from "./LeftPanel";

const style = {
  pageLayout: css({
    display: "flex",
    height: "100vh",
    width: "-webkit-fill-available",
    position: "sticky",
  }),
  rightHeaderSection: css({
    background: white,
    borderBottom: `1px solid ${uiGray[10]}`,
    height: 60,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    position: "sticky",
    top: 0,
    display: "inline-block",
    zIndex: 1,
  }),
  content: css({
    flex: 1,
    overflow: "hidden",
  }),
  rightPanel: css({
    width: 345,
    flexShrink: 0,
    borderLeft: `1px solid ${uiGray[10]}`,
    background: uiGray.pageBackground,
    overflowY: "auto",
    [down("desktop")]: {
      display: "none",
    },
  }),
  search: css({
    paddingTop: 8,
    paddingLeft: 12,
  }),
};

type Props = {
  showRightPanel?: boolean;
};

export const PageLayout = ({
  children,
  showRightPanel,
}: PropsWithChildren<Props>) => {
  const router = useRouter();
  return (
    <PageLayoutProvider>
      <div>
        <div css={style.pageLayout}>
          <LeftPanel />
          <div css={style.content}>{children}</div>

          {showRightPanel && (
            <div css={style.rightPanel}>
              <div css={style.rightHeaderSection}>
                <Authenticated>
                  <div css={style.search}>
                    <SearchBar />
                  </div>
                </Authenticated>
              </div>

              <Authenticated>
                {router.pathname === getEarningsUrl() && (
                  <ErrorBoundary>
                    <EarningsRightPanel />
                  </ErrorBoundary>
                )}

                {router.pathname === getDocumentsUrl() && (
                  <DocumentsRightPanel />
                )}
                {router.pathname === getResumeUrl() && <ResumeRightPanel />}
                {router.pathname === getJobsCopilotUrl() && (
                  <JobsCopilotRightPanel />
                )}
                {router.pathname.includes(getGenieOverviewUrl()) &&
                  router.pathname !== getGenieOverviewUrl() && (
                    <ErrorBoundary>
                      <ChatHistory isSidePanel key={router.pathname} />
                    </ErrorBoundary>
                  )}
                {router.pathname.includes("interview") &&
                  !router.pathname.includes("my-interview") && (
                    <InterviewRightPanel />
                  )}
                {(router.pathname === getCommunityUrl() ||
                  router.pathname === "/p/[handle]") && <Recommendations />}
              </Authenticated>
            </div>
          )}
        </div>
      </div>
    </PageLayoutProvider>
  );
};
