import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

import {
  MenuItem,
  Props as MenuItemProps,
} from "components/shared/library/MenuItem";

import { usePageLayoutContext } from "contexts/PageLayoutContext";

import { NavigationBadge } from "./NavigationBadge";
import { SidePanelTooltip } from "./SidePanelTooltip";

export type Props = MenuItemProps & {
  isNew?: boolean;
  RightComponent?: EmotionJSX.Element;
  SecondaryComponent?: EmotionJSX.Element;
};

export const SidePanelItem = ({
  isNew,
  label,
  link,
  Icon,
  RightComponent: passedRightComponent,
  SecondaryComponent,
  disableChildPathAsActive,
}: Props) => {
  const { leftPanelStatus } = usePageLayoutContext();
  const RightComponent = isNew ? (
    <NavigationBadge label={"New"} />
  ) : (
    passedRightComponent
  );
  return (
    <SidePanelTooltip label={label}>
      <MenuItem
        label={label}
        link={link}
        Icon={Icon}
        RightComponent={RightComponent}
        iconOnly={leftPanelStatus === "collapsed"}
        disableChildPathAsActive={disableChildPathAsActive}
      />
      {SecondaryComponent}
    </SidePanelTooltip>
  );
};
